import React from 'react';
import Select, { createFilter } from 'react-select';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';

const customStyles = {
  container: (provided) => ({
    ...provided,
    width: '350px',
    margin: '0 8px'
  }),
  menu: (provided) => ({
    ...provided,
    width: '350px',
  }),
  control: (provided) => ({
    ...provided,
    minHeight: '37px',
    maxHeight: '37px',
    height: '37px',
  }),
  valueContainer: (provided) => ({
    ...provided,
    maxHeight: '37px',
    overflow: 'auto',
    display: 'flex',
    flexWrap: 'nowrap',
    whiteSpace: 'nowrap',
    padding: '2px 8px',
    msOverflowStyle: 'none', /* IE и Edge */
    scrollbarWidth: 'none', /* Firefox */
    '&::-webkit-scrollbar': { /* Chrome, Safari и Opera */
      display: 'none'
    }
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: '#f3f4f6',
    borderRadius: '4px',
    flexShrink: 0,
    margin: '2px 4px',
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    fontSize: '14px',
    padding: '2px 6px',
    whiteSpace: 'nowrap',
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    borderRadius: '0 4px 4px 0',
    '&:hover': {
      backgroundColor: '#e5e7eb',
      color: '#ef4444',
    },
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    height: '35px',
  }),
  placeholder: (provided) => ({
    ...provided,
    whiteSpace: 'nowrap',
  }),
};

const LandingSelect = ({ landings, selectedLandings, onChange, toggleFavorite, setLandings }) => {
  // Преобразуем входящие landings в options
  const options = landings.map(landing => ({
    value: landing.id.toString(),
    label: landing.name,
    isFavorite: landing.is_favorite
  }));

  // Сортировка опций: сначала 'all', затем избранные, затем по алфавиту
  options.sort((a, b) => {
    if (a.value === 'all') return -1;
    if (b.value === 'all') return 1;
    
    if (a.isFavorite && !b.isFavorite) return -1;
    if (!a.isFavorite && b.isFavorite) return 1;
    
    return a.label.localeCompare(b.label);
  });

  const handleChange = (selectedOptions) => {
    // Если нет выбранных опций, устанавливаем 'all'
    if (!selectedOptions || selectedOptions.length === 0) {
      onChange(['all']);
      return;
    }

    // Проверяем, выбран ли 'all' среди новых опций
    const hasAll = selectedOptions.some(opt => opt.value === 'all');
    const wasAll = selectedLandings.includes('all');

    // Если выбран 'all'
    if (hasAll && !wasAll) {
      onChange(['all']);
      return;
    }

    // Если был выбран 'all', а теперь выбираем конкретный лендинг
    if (wasAll && !hasAll) {
      const lastSelected = selectedOptions[selectedOptions.length - 1];
      onChange([lastSelected.value]);
      return;
    }

    // В остальных случаях передаем все выбранные значения
    const values = selectedOptions.map(opt => opt.value);
    onChange(values.filter(v => v !== 'all')); // Убираем 'all' из множественного выбора
  };

  const formatOptionLabel = ({ value, label, isFavorite }) => (
    <div className="custom-select-option">
      {value !== 'all' && (
        <span 
          onClick={(e) => {
            e.stopPropagation();
            toggleFavorite(value, !isFavorite, 'landing', setLandings);
          }}
          style={{ display: 'inline-flex', alignItems: 'center', marginRight: '8px' }}
        >
          {isFavorite ? (
            <FavoriteIcon className="star-icon always-visible" style={{ fontSize: '20px' }} />
          ) : (
            <FavoriteBorderIcon className="star-icon star-border-icon" style={{ fontSize: '20px' }} />
          )}
        </span>
      )}
      {label}
    </div>
  );

  // Определяем текущие выбранные значения
  const value = selectedLandings.includes('all')
    ? [options.find(opt => opt.value === 'all')]
    : options.filter(opt => selectedLandings.includes(opt.value.toString()));

  return (
    <Select
      isMulti
      value={value}
      onChange={handleChange}
      options={options}
      formatOptionLabel={formatOptionLabel}
      styles={customStyles}
      filterOption={createFilter({ ignoreCase: true })}
      placeholder="Select landings..."
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      menuPlacement="auto"
    />
  );
};

export default LandingSelect;