import React, { useState, useEffect, useRef } from 'react';
import DomainSelect from './DomainSelect';
import LandingSelect from './LandingSelect';
import TrafficSourceSelect from './TrafficSourceSelect';
import { DateRange } from 'react-date-range';
import { addDays, format } from 'date-fns';
import toast, { Toaster } from 'react-hot-toast';
import Select from 'react-select';
import TimePicker from './TimePicker';
import SortableTable from './SortableTable';
import CountrySelect from './CountrySelect';

import styled from 'styled-components';

const DateRangePickerWrapper = styled.div`
  display: inline-block;
  position: relative;
`;

const DateRangeInput = styled.input`
  padding: 8px 12px;
  font-size: 16px;
  height: 37px;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color ease-in-out 0.15s,box-shadow ease-in-out 0.15s;
  cursor: pointer;
`;

const DateRangePickerPopup = styled.div`
  background-color: #fff;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 9999;
`;

const ButtonWrapper = styled.div`
  margin-left: 10px;
`;

const SubmitButton = styled.button`
  padding: 8px 20px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

const Preloader = styled.div`
  display: inline-block;
  margin-left: 10px;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #ccc;
  border-top-color: #007bff;
  animation: spin 1s infinite linear;

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;

const RegistrationApp = ({ domains: initialDomainsData, landings: initialLandingData }) => {
  const [domains, setDomains] = useState(initialDomainsData);
  const [landings, setLandings] = useState(initialLandingData);
  const [queryHistory, setQueryHistory] = useState([]);
  const [selectedDomain, setSelectedDomain] = useState('all');
  const [selectedLandings, setSelectedLandings] = useState(['all']);
  const [landingOptions, setLandingOptions] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [statistics, setStatistics] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const fetchInProgressRef = useRef(false);
  const [customQuery, setCustomQuery] = useState('');
  const [selectedSnippet, setSelectedSnippet] = useState('');
  const [selectedCountry, setSelectedCountry] = useState({ value: 'All', label: 'All Countries' });
  const [selectedTrafficSource, setSelectedTrafficSource] = useState({ value: 'all', label: 'All Sources' });



  // Стейт для хранения предыдущих выбранных лендингов
  const [prevSelectedLandings, setPrevSelectedLandings] = useState({ first: '', second: '' });


  // startDate и endDate в selectedDateRange по умолчанию должны иметь время от 00:00 до 23:59
  const [selectedDateRange, setSelectedDateRange] = useState([
    {
      // startDate и endDate в selectedDateRange по умолчанию должны иметь время от 00:00 до 23:59
      startDate: new Date(new Date().setHours(0, 0, 0, 0)),
      endDate: new Date(new Date().setHours(23, 59, 59, 999)),
      key: 'selection',
    },
  ]);

  const [isDateRangePickerOpen, setIsDateRangePickerOpen] = useState(false);
  const dateRangePickerRef = useRef();

  const toggleFavorite = async (itemId, newFavoriteStatus, type, setState) => {
    try {
      const response = await fetch('/favorites/update', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        },
        body: JSON.stringify({ type: type, id: itemId, is_favorite: newFavoriteStatus })
      });

      if (response.ok) {
        // Update the local state to reflect the change
        setState(prevItems => prevItems.map(item =>
          item.id === itemId ? { ...item, is_favorite: newFavoriteStatus } : item
        ));
      } else {
        // Handle error
        console.error('Failed to update favorite status');
      }
    } catch (error) {
      console.error('Error updating favorite status:', error);
    }
  };

  useEffect(() => {
    if (selectedDomain === 'all') {
      setLandingOptions([{ id: 'all', name: 'All Landings' }]);
    } else {
      const filteredLandings = landings.filter(landing => landing.domain_id === parseInt(selectedDomain));
      setLandingOptions([
        { id: 'all', name: 'All Landings' },
        ...filteredLandings
      ]);
    }
  }, [selectedDomain, landings]);

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);


  const handleFormSubmit = async () => {
    if (!fetchInProgressRef.current) {
      setIsSubmitting(true);
      fetchInProgressRef.current = true;
   
      if (customQuery && !queryHistory.includes(customQuery)) {
        setQueryHistory([customQuery, ...queryHistory].slice(0, 10));
      }
   
      const formattedDateRange = [
        {
          startDate: selectedDateRange[0].startDate.toISOString(),
          endDate: selectedDateRange[0].endDate.toISOString(),
        },
      ];
   
      const page = currentPage === 0 ? 1 : currentPage;
      const token = document.querySelector('meta[name="csrf-token"]').content;
      try {
        const response = await fetch('/api/requests/registrations', {
          method: 'POST',
          body: JSON.stringify({
            selectedDomain,
            selectedLandings,
            dateRange: formattedDateRange,
            searchQuery,
            page,
            customQuery,
            selectedSnippet,
            selectedCountry: selectedCountry.value,
            traffic_source: selectedTrafficSource.value === 'all' ? null : selectedTrafficSource.value
          }),
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': token,
          },
        });
        const data = await response.json();
        setStatistics(data.statistics);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsSubmitting(false);
        fetchInProgressRef.current = false;
      }
    }
   };

  const handleDateRangeChange = (ranges) => {
    const currentStartDate = selectedDateRange[0].startDate;
    const currentEndDate = selectedDateRange[0].endDate;
  
    const startHours = currentStartDate.getHours();
    const startMinutes = currentStartDate.getMinutes();
    const endHours = currentEndDate.getHours();
    const endMinutes = currentEndDate.getMinutes();
  
    const newStartDate = new Date(ranges.selection.startDate);
    newStartDate.setHours(startHours, startMinutes);
  
    const newEndDate = new Date(ranges.selection.endDate);
    newEndDate.setHours(endHours, endMinutes);
  
    setSelectedDateRange([
      {
        startDate: newStartDate,
        endDate: newEndDate,
        key: 'selection',
      },
    ]);
  };

  const handleDateRangeFieldClick = () => {
    setIsDateRangePickerOpen(!isDateRangePickerOpen);
  };

  const handleOutsideClick = (event) => {
    if (dateRangePickerRef.current && !dateRangePickerRef.current.contains(event.target)) {
      setIsDateRangePickerOpen(false);
    }
  };

  const handleDomainChange = (event) => {
    const selectedDomainId = event.target.value;
    setSelectedDomain(selectedDomainId);
    setSelectedLandings(['all']);
  };

  useEffect(() => {
    handleFormSubmit();
  }, [currentPage])

  const handleLandingChange = (event) => {
    setSelectedLanding(event.target.value);
  };

  const handleTimeChange = (newDateRange) => {
    // Обновляем selectedDateRange при изменении времени
    setSelectedDateRange(newDateRange);
  };
  
  const dropdownRef = useRef(null);
  const dropdownButtonRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownButtonRef.current && dropdownButtonRef.current.contains(event.target)) {
        // Если клик произошел на кнопке, не делаем ничего
        return;
      }
  
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
  
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  

  


  return (
    <div className="wrapper">
      <div className="block sticky-top">
        <DomainSelect
          domains={domains}
          selectedDomain={selectedDomain}
          onChange={handleDomainChange}
          toggleFavorite={toggleFavorite}
          setDomains={setDomains}
        />

        <LandingSelect
          landings={landingOptions}
          selectedLandings={selectedLandings}
          onChange={setSelectedLandings}
          toggleFavorite={toggleFavorite}
          setLandings={setLandings}
        />

        <CountrySelect
          selectedCountry={selectedCountry}
          onChange={setSelectedCountry}
        />

        <DateRangePickerWrapper ref={dateRangePickerRef}>
          <DateRangeInput
            type="text"
            value={`${format(selectedDateRange[0].startDate, 'dd/MM/yyyy HH:mm')} - ${format(
              selectedDateRange[0].endDate,
              'dd/MM/yyyy HH:mm'
            )}`}
            readOnly
            onClick={handleDateRangeFieldClick}
            className="datepicker-input"
          />
          {isDateRangePickerOpen && (
            <DateRangePickerPopup>
              <DateRange
                ranges={selectedDateRange}
                onChange={handleDateRangeChange}
              />
              <div className="time-picker">
                <TimePicker
                  selectedDateRange={selectedDateRange}
                  onChange={handleTimeChange}
                />
              </div>
            </DateRangePickerPopup>
          )}
        </DateRangePickerWrapper>

        <TrafficSourceSelect
          selectedTrafficSource={selectedTrafficSource}
          onChange={setSelectedTrafficSource}
        />

        <ButtonWrapper>
          {!isSubmitting ? (
            <SubmitButton onClick={handleFormSubmit}>Search</SubmitButton>
          ) : (
            <SubmitButton disabled>
              <Preloader />
            </SubmitButton>
          )}
        </ButtonWrapper>
      </div>

      {statistics && Object.keys(statistics).length > 0 ? (
        <div className="block block-column">
          <SortableTable data={statistics} />
        </div>
      ) : null}
    </div>
  );
}

export default RegistrationApp;