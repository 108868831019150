import React, { useEffect, useState, useRef } from 'react';
import { DateRange } from 'react-date-range';
import { addDays, format } from 'date-fns';
import { enUS } from 'date-fns/locale';
import DomainSelect from './DomainSelect';
import LandingSelect from './LandingSelect';
import CountrySelect from './CountrySelect';
import LineChart from './LineChart';

const ERROR_CATEGORIES = {
  "Phone validation": [
    "phone number", "number", "телефон", "номер", "telefon", "número", "phone", "nambari", "номер телефона",
    "telephone", "puhelinnumero", "電話番号", "전화번호", "โทรศัพท์", "ฟอน", "電話", "تلفن",
    "رقم الهاتف", // арабский
    "nomor telepon salah", // индонезийский
    "numéro incorrect", // французский
    "exemple" // французский пример
  ],
  
  "Code errors": {
    "Expired code": [
      "expired", "истек", "caducado", "lejárt", "wygasł", "kedaluwarsa", "หมดอายุ", "过期",
      "срок действия", "pasibaigęs", "amal qilish", "срок", "час дії", "timeout", "time out",
      "expiré", "abgelaufen", // немецкий
      "انتهت صلاحية", // арабский
      "código expirou", // португальский
      "वैधता समाप्त"
    ],
    "Invalid code": [
      "incorrect code", "yanlış kod", "wrong code", "неверный код", "código errado", "cod incorect", "invalid code",
      "wrong confirmation", "błędny kod", "kode salah", "codigo invalido", "รหัสไม่ถูกต้อง", "コード",
      "confirmation code", "код подтверждения", "código de confirmación",
      "code incorrect",
      "msimbo si sahihi",
      "felaktig kod", // шведский
      "código incorrecto", // испанский
      "неважећи код", // сербский
      "רמז שגוי", // иврит
      "رمز خاطئ", // арабский
      "neispravan kod", // хорватский
      "código incorreto", // португальский
      "अवैध कोड", // хинди
      "namba ya uthibitisho" 
    ],
    "Multiple attempts": [
      "several times", "несколько раз", "varias vezes", "több alkalommal", "plusieurs fois",
      "多次", "여러 번", "หลายครั้ง", "try again later", "попробуйте позже", "please try again",
      "incorrectement à plusieurs reprises", // добавляем французскую фразу
      "تم إدخال رمز التأكيد بشكل غير صحيح", // арабский
      "पुष्टिकरण कोड कई बार गलत", // хинди
      "uliingiza namba ya uthibitisho kimakosa"
    ]
  },

  "Currency errors": [
    "currency", "devise", "валюта", "moneda", "валюта", "währung", "moeda", "货币", "통화", "สกุลเงิน",
    "invalid currency" // добавляем прямое указание
  ],

  "Country restrictions": [
    "country", "страна", "país", "국가", "国", "ประเทศ", "państwo", "land", "país",
    "we do not accept registrations", "не принимаем регистрации", "registration suspended",
    "registration paused",
    "hatukubali usajili", // суахили
    "n'acceptons pas d'inscriptions", // французский
    "лا نقبل التسجيلات", // арабский
    "איננו מקבלים הרשמות", // иврит
    "не прихватамо регистрације", // сербский
    "عذرًا، لا نقبل التسجيلات من بلدك", // полная арабская фраза
    "في الوقت الحالي"
  ],

  "Duplicate registration": [
    "already registered", "already exists", "уже зарегистрирован", "já registrado", "bereits registriert",
    "déjà enregistré", "已注册", "이미 등록", "ลงทะเบียนแล้ว", "multiple accounts", "existing",
    "duplicate", "дубликат", "registered", "зарегистрирован", "exists",
    "هذا الرقم مسجل من قبل", // добавляем арабскую версию
     "पहले से ही पंजीकृत"
  ],

  "Format errors": [
    "invalid format", "неверный формат", "formato inválido", "format invalide", "ungültiges format",
    "formát neplatný", "形式が無効", "형식이 잘못", "รูปแบบไม่ถูกต้อง", "wrong format",
    "must contain", "should contain", "должен содержать", "email format", "format", "формат email",
    "doit contenir au moins", // французский требования к паролю
    "caractères", // французский
    "lettres et des chiffres"
  ],

  "System errors": [
    "something went wrong", "error", "ошибка", "erro", "fehler", "erreur", "错误", "오류", "ข้อผิดพลาด",
    "timed out", "timeout", "истекло время", "tempo esgotado", "zeitüberschreitung", "temps écoulé",
    "超时", "시간 초과", "หมดเวลา", "internal error", "внутренняя ошибка", "server error"
  ],

  "Other validation": [
    "validation", "валидация", "validação", "validierung", "validation", "验证", "유효성", "การตรวจสอบ",
    "not permitted", "не разрешено", "não permitido", "nicht erlaubt", "non autorisé", "不允许", 
    "허용되지 않음", "ไม่อนุญาต", "no data", "нет данных", "parameters", "wrong sign", "параметры"
  ]
};

const customLocale = {
 ...enUS,
 options: {
   ...enUS.options,
   weekStartsOn: 1
 }
};

const getMonthNumber = (monthStr) => {
  const months = {
    'Jan': 1, 'Feb': 2, 'Mar': 3, 'Apr': 4, 'May': 5, 'Jun': 6,
    'Jul': 7, 'Aug': 8, 'Sep': 9, 'Oct': 10, 'Nov': 11, 'Dec': 12
  };
  return months[monthStr] || 1;
};

const CustomTooltip = ({ content, children, originalMessages }) => {
 const [isVisible, setIsVisible] = useState(false);
 const ref = useRef(null);

 return (
   <th 
     ref={ref}
     className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider relative"
     onMouseEnter={() => setIsVisible(true)}
     onMouseLeave={() => setIsVisible(false)}
   >
     {children}
     {isVisible && originalMessages && (
       <div 
         className="absolute z-[100] top-full left-1/2 transform -translate-x-1/2 px-3 py-2 mt-2 bg-black text-white text-sm rounded-md shadow-lg"
         style={{ pointerEvents: 'none', maxWidth: '500px', minWidth: '200px' }}
       >
         <div className="absolute -top-1 left-1/2 transform -translate-x-1/2 w-2 h-2 bg-black rotate-45"></div>
         <div className="font-bold mb-2">{content}</div>
         <div className="text-xs text-gray-300">
           <div className="font-semibold mb-1">Errors included:</div>
           <ul className="list-disc list-inside space-y-1">
             {Array.isArray(originalMessages) ? 
               originalMessages.map((message, index) => (
                 <li key={index} className="break-all">{message}</li>
               )) : 
               <li>{originalMessages}</li>
             }
           </ul>
         </div>
       </div>
     )}
   </th>
 );
};

const categorizeError = (errorMessage, errorCode) => {
  // Проверяем код ошибки 429 или большой размер ответа
  if (errorCode === '429') {
    return "Too many requests";
  }
  
  // Если сообщение пустое, но есть код ошибки
  if (!errorMessage && errorCode) {
    return `Status ${errorCode}`;
  }
  
  if (!errorMessage) return 'No message errors';
  
  const lowercaseMessage = errorMessage.toLowerCase().trim();
 
  // Проверяем подкатегории для кодов
  if (ERROR_CATEGORIES["Code errors"]["Expired code"].some(keyword => 
    lowercaseMessage.includes(keyword.toLowerCase())
  )) {
    return "Expired code";
  }
 
  if (ERROR_CATEGORIES["Code errors"]["Invalid code"].some(keyword => 
    lowercaseMessage.includes(keyword.toLowerCase())
  )) {
    return "Invalid code";
  }
 
  if (ERROR_CATEGORIES["Code errors"]["Multiple attempts"].some(keyword => 
    lowercaseMessage.includes(keyword.toLowerCase())
  )) {
    return "Multiple attempts";
  }
 
  // Проверяем остальные категории
  for (const [category, keywords] of Object.entries(ERROR_CATEGORIES)) {
    if (category === "Code errors") continue;
 
    if (Array.isArray(keywords) && keywords.some(keyword => 
      lowercaseMessage.includes(keyword.toLowerCase())
    )) {
      return category;
    }
  }
 
  return errorCode ? `Status ${errorCode}` : 'Unknown';
};

const ErrorTable = ({ domains: initialDomainsData, landings: initialLandingData }) => {
 const [domains, setDomains] = useState(initialDomainsData || []);
 const [landings, setLandings] = useState(initialLandingData || []);
 const [selectedDomain, setSelectedDomain] = useState('all');
 const [selectedLandings, setSelectedLandings] = useState(['all']);
 const [selectedCountry, setSelectedCountry] = useState({ value: 'All', label: 'All Countries' });
 const [landingOptions, setLandingOptions] = useState([]);
 const [isDateRangePickerOpen, setIsDateRangePickerOpen] = useState(false);
 const [sortedErrors, setSortedErrors] = useState({});
 const [isSubmitting, setIsSubmitting] = useState(false);
 const [isLoading, setIsLoading] = useState(true);
 const [error, setError] = useState(null);

 const dateRangePickerRef = useRef();

 const [selectedDateRange, setSelectedDateRange] = useState([
   {
     startDate: addDays(new Date(), -7),
     endDate: new Date(),
     key: 'selection',
   },
 ]);

 useEffect(() => {
   if (selectedDomain === 'all') {
     setLandingOptions([{ id: 'all', name: 'All Landings' }]);
   } else {
     const filteredLandings = landings.filter(
       landing => landing.domain_id === parseInt(selectedDomain)
     );
     setLandingOptions([
       { id: 'all', name: 'All Landings' },
       ...filteredLandings
     ]);
   }
 }, [selectedDomain, landings]);

 useEffect(() => {
   document.addEventListener('mousedown', handleOutsideClick);
   handleFormSubmit();
   
   return () => {
     document.removeEventListener('mousedown', handleOutsideClick);
   };
 }, []);

 const transformErrorData = (data) => {
  const getMonthNumber = (monthStr) => {
    const months = {
      'Jan': 1, 'Feb': 2, 'Mar': 3, 'Apr': 4, 'May': 5, 'Jun': 6,
      'Jul': 7, 'Aug': 8, 'Sep': 9, 'Oct': 10, 'Nov': 11, 'Dec': 12
    };
    return months[monthStr] || 1;
  };
 
  const errorTypes = new Set();
  const dailyData = {};
  const errorMessages = {};
 
  const allCategories = [
    ...Object.keys(ERROR_CATEGORIES),
    "Expired code",
    "Invalid code", 
    "Multiple attempts",
    "Too many requests",
    "No message errors",
    "Unknown"
  ];
 
  // Сортируем записи по возрастанию даты
  const sortedEntries = Object.entries(data).sort((a, b) => {
    const [dayA, monthA, yearA] = a[0].split(' ');
    const [dayB, monthB, yearB] = b[0].split(' ');
    const dateA = new Date(`${yearA}-${getMonthNumber(monthA)}-${dayA}`);
    const dateB = new Date(`${yearB}-${getMonthNumber(monthB)}-${dayB}`);
    return dateA - dateB;
  });
 
  sortedEntries.forEach(([timestamp, errors]) => {
    const [day, month, year, time] = timestamp.split(' ');
    const formattedDate = `${year}-${String(getMonthNumber(month)).padStart(2, '0')}-${day.padStart(2, '0')} ${time}`;
    
    if (!dailyData[formattedDate]) {
      dailyData[formattedDate] = { total: 0 };
      allCategories.forEach(category => {
        dailyData[formattedDate][category] = 0;
      });
    }
 
    errors.forEach(error => {
      const category = categorizeError(error.error_message, error.error_code);
      
      if (category.startsWith('Status ') && !dailyData[formattedDate][category]) {
        dailyData[formattedDate][category] = 0;
      }
 
      errorTypes.add(category);
      
      if (!errorMessages[category]) {
        errorMessages[category] = new Set();
      }
      
      if (error.error_message) {
        errorMessages[category].add(error.error_message);
      } else if (error.error_code) {
        errorMessages[category].add(`HTTP Status ${error.error_code}`);
      } else {
        errorMessages[category].add('No error message');
      }
      
      dailyData[formattedDate][category] += error.error_count || 0;
      dailyData[formattedDate].total += error.error_count || 0;
    });
  });
 
  // Создаем отсортированный объект dailyData
  const sortedDailyData = Object.fromEntries(
    Object.entries(dailyData).sort((a, b) => new Date(a[0]) - new Date(b[0]))
  );
 
  return {
    errorTypes: Array.from(errorTypes),
    dailyData: sortedDailyData,
    errorMessages: Object.fromEntries(
      Object.entries(errorMessages).map(([category, messages]) => [
        category,
        Array.from(messages)
      ])
    )
  };
 };

const handleFormSubmit = async () => {
  setIsSubmitting(true);
  setError(null);

  try {
    const token = document.querySelector('meta[name="csrf-token"]')?.content;
    
    if (!token) {
      throw new Error('CSRF token not found');
    }

    // Создаем даты без изменения временной зоны
    const startDate = new Date(selectedDateRange[0].startDate);
    startDate.setHours(0, 0, 0, 0);

    const endDate = new Date(selectedDateRange[0].endDate);
    endDate.setHours(23, 59, 59, 999);

    const response = await fetch('/api/errors/search', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': token,
      },
      body: JSON.stringify({
        selectedDomain,
        selectedLandings,
        selectedCountry: selectedCountry.value,
        dateRange: [{
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString()
        }],
      }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    setSortedErrors(data.errors || {});
  } catch (err) {
    console.error('Error fetching data:', err);
    setError('Failed to load error data. Please try again.');
  } finally {
    setIsSubmitting(false);
    setIsLoading(false);
  }
};

 const handleDateRangeChange = (ranges) => {
   setSelectedDateRange([ranges.selection]);
 };

 const handleDateRangeFieldClick = () => {
   setIsDateRangePickerOpen(!isDateRangePickerOpen);
 };

 const handleOutsideClick = (event) => {
   if (dateRangePickerRef.current && !dateRangePickerRef.current.contains(event.target)) {
     setIsDateRangePickerOpen(false);
   }
 };

 const handleDomainChange = (event) => {
   const selectedDomainId = event.target.value;
   setSelectedDomain(selectedDomainId);
   setSelectedLandings(['all']);
 };

 const handleLandingChange = (event) => {
   setSelectedLanding(event.target.value);
 };

 const truncateErrorMessage = (message, maxWords = 3) => {
   if (!message) return '';
   const words = message.split(' ');
   if (words.length <= maxWords) return message;
   return `${words.slice(0, maxWords).join(' ')}...`;
 };

 if (isLoading) {
   return <div className="flex justify-center items-center p-5 text-gray-600">Loading error data...</div>;
 }

 if (error) {
   return <div className="p-4 text-red-700 bg-red-100 rounded-md">{error}</div>;
 }

 const { errorTypes, dailyData, errorMessages } = transformErrorData(sortedErrors);

 const convertToCSV = (errorTypes, dailyAggregated) => {
    // Заголовки
    const headers = ['Date', ...errorTypes, 'Total'];
    
    // Преобразуем данные
    const rows = Object.entries(dailyAggregated).map(([date, counts]) => {
      const [year, month, day] = date.split('-');
      const formattedDate = `${day}.${month}.${year}`;
      
      return [
        formattedDate,
        ...errorTypes.map(type => counts[type] || 0),
        counts.total
      ];
    });

    // Добавляем строку с тотальными значениями
    const totalRow = [
      'Total',
      ...errorTypes.map(type => 
        Object.values(dailyAggregated).reduce((sum, counts) => sum + (counts[type] || 0), 0)
      ),
      Object.values(dailyAggregated).reduce((sum, counts) => sum + counts.total, 0)
    ];

    // Собираем все строки
    const allRows = [headers, ...rows, totalRow];
    
    // Конвертируем в CSV
    return allRows.map(row => row.join(',')).join('\n');
  };

 return (
   <div className="wrapper">
     <div className="block sticky-top bg-white shadow-sm p-4 mb-4 rounded-lg">
       <div className="grid grid-cols-4 gap-4">

         {/* Фильтры */}
         <div className="col-span-4 flex flex-wrap gap-4">
           <DomainSelect
             domains={domains}
             selectedDomain={selectedDomain}
             onChange={handleDomainChange}
             setDomains={setDomains}
           />

            <LandingSelect
              landings={landingOptions}
              selectedLandings={selectedLandings}
              onChange={setSelectedLandings}
              setLandings={setLandings}
            />

          <CountrySelect
            selectedCountry={selectedCountry}
            onChange={setSelectedCountry}
          />

           <div className="relative" ref={dateRangePickerRef}>
             <input
               type="text"
               className="px-3 py-2 border rounded-md cursor-pointer focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
               value={`${format(selectedDateRange[0].startDate, 'dd/MM/yyyy')} - ${format(
                 selectedDateRange[0].endDate,
                 'dd/MM/yyyy'
               )}`}
               readOnly
               onClick={handleDateRangeFieldClick}
             />
             {isDateRangePickerOpen && (
               <div className="absolute top-full left-0 z-50 mt-1 bg-white shadow-lg rounded-lg">
                 <DateRange
                   ranges={selectedDateRange}
                   onChange={handleDateRangeChange}
                   locale={customLocale}
                   months={1}
                   direction="horizontal"
                   showSelectionPreview={true}
                   moveRangeOnFirstSelection={false}
                 />
               </div>
             )}
           </div>
           
           <button
                onClick={handleFormSubmit}
                disabled={isSubmitting}
                className={`px-4 py-2 rounded-md font-medium transition-colors ${
                  isSubmitting 
                    ? 'bg-gray-400 cursor-not-allowed' 
                    : 'bg-blue-600 hover:bg-blue-700 text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2'
                }`}
              >
                {isSubmitting ? (
                  <div className="w-5 h-5 border-2 border-gray-200 border-t-gray-600 rounded-full animate-spin"></div>
                ) : (
                  'Search'
                )}
            </button>

           
         </div>
       </div>
     </div>

     <div className="p-2">
        {/* Информационный блок */}
        <div className="grid grid-cols-5 gap-4 mb-5">
          <div className="col-span-1 h-fit bg-white shadow-md rounded-lg p-4">
            <div className="text-sm">
              <span className="font-semibold text-gray-600">Domain: </span>
              <span className="text-blue-600">
                {selectedDomain === 'all' ? 'All Domains' : domains.find(d => d.id === parseInt(selectedDomain))?.name || 'All Domains'}
              </span>
            </div>
            <div className="text-sm">
              <span className="font-semibold text-gray-600">Landing: </span>
              <span className="text-blue-600">
                {selectedLandings.includes('all') 
                  ? 'All Landings' 
                  : selectedLandings.map(id => 
                      landings.find(l => l.id.toString() === id)?.name
                    ).filter(Boolean).join(', ') || 'All Landings'
                }
              </span>
            </div>
            <div className="text-sm">
              <span className="font-semibold text-gray-600">Country: </span>
              <span className="text-blue-600">
                {selectedCountry.label}
              </span>
            </div>
            <div className="text-sm">
              <span className="font-semibold text-gray-600">Period: </span>
              <span className="text-blue-600">
                {format(selectedDateRange[0].startDate, 'dd/MM/yyyy')} - {format(selectedDateRange[0].endDate, 'dd/MM/yyyy')}
              </span>
            </div>
            <div className="pt-2 mt-2 border-t border-gray-200">
              <button
                onClick={() => {
                  // Группируем данные по дням
                  const dailyAggregated = {};
                  Object.entries(dailyData).forEach(([timestamp, counts]) => {
                    const [datePart] = timestamp.split(' ');
                    if (!dailyAggregated[datePart]) {
                      dailyAggregated[datePart] = {
                        total: 0,
                        ...errorTypes.reduce((acc, type) => ({ ...acc, [type]: 0 }), {})
                      };
                    }
                    errorTypes.forEach(errorType => {
                      dailyAggregated[datePart][errorType] += counts[errorType] || 0;
                    });
                    dailyAggregated[datePart].total += counts.total;
                  });

                  // Генерируем CSV
                  const csv = convertToCSV(errorTypes, dailyAggregated);
                  
                  // Создаем и скачиваем файл
                  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
                  const link = document.createElement('a');
                  const url = URL.createObjectURL(blob);
                  
                  link.setAttribute('href', url);
                  link.setAttribute('download', 
                    `errors_${format(selectedDateRange[0].startDate, 'dd-MM-yyyy')}_${format(selectedDateRange[0].endDate, 'dd-MM-yyyy')}.csv`
                  );
                  link.style.visibility = 'hidden';
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                  URL.revokeObjectURL(url);
                }}
                className="w-full px-4 py-2 text-sm font-medium text-white bg-green-600 rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
              >
                Export to CSV
              </button>
            </div>
          </div>
        </div>

        {/* Таблица */}
        {Object.keys(dailyData).length > 0 ? (
          <>
          <div className="mt-4 mb-4">
            <LineChart data={dailyData} errorTypes={errorTypes} />
          </div>
          <div className="w-full overflow-hidden bg-white shadow-md rounded-lg">
            
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="sticky top-0 bg-gray-50 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Date
                    </th>
                    {errorTypes.map(errorType => (
                      <CustomTooltip 
                        key={errorType} 
                        content={errorType}
                        originalMessages={errorMessages[errorType]}
                      >
                        {truncateErrorMessage(errorType)}
                      </CustomTooltip>
                    ))}
                    <th className="sticky top-0 bg-gray-50 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Total
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                {(() => {
                    // Группируем данные по дням
                    const dailyAggregated = {};
                    
                    Object.entries(dailyData).forEach(([timestamp, counts]) => {
                      const [datePart] = timestamp.split(' ');
                      
                      if (!dailyAggregated[datePart]) {
                        dailyAggregated[datePart] = {
                          total: 0,
                          ...errorTypes.reduce((acc, type) => ({ ...acc, [type]: 0 }), {})
                        };
                      }
                      
                      // Суммируем значения за день
                      errorTypes.forEach(errorType => {
                        dailyAggregated[datePart][errorType] += counts[errorType] || 0;
                      });
                      dailyAggregated[datePart].total += counts.total;
                    });

                    return (
                      <>
                        {/* Строки с данными по дням */}
                        {Object.entries(dailyAggregated)
                          .sort(([dateA], [dateB]) => dateB.localeCompare(dateA))
                          .map(([date, counts]) => {
                            const [year, month, day] = date.split('-');
                            const formattedDate = `${day}.${month}.${year}`;

                            return (
                              <tr key={date} className="hover:bg-gray-50">
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                  {formattedDate}
                                </td>
                                {errorTypes.map(errorType => (
                                  <td 
                                    key={`${date}-${errorType}`} 
                                    className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center"
                                  >
                                    {counts[errorType] || 0}
                                  </td>
                                ))}
                                <td className="px-6 py-4 whitespace-nowrap text-sm font-semibold bg-gray-50">
                                  {counts.total}
                                </td>
                              </tr>
                            );
                          })}
                          
                        {/* Итоговая строка */}
                        <tr className="bg-gray-100 font-bold">
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                            Total
                          </td>
                          {errorTypes.map(errorType => (
                            <td 
                              key={`total-${errorType}`} 
                              className="px-6 py-4 whitespace-nowrap text-sm text-gray-700 text-center"
                            >
                              {Object.values(dailyAggregated).reduce((sum, counts) => sum + (counts[errorType] || 0), 0)}
                            </td>
                          ))}
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 bg-gray-200">
                            {Object.values(dailyAggregated).reduce((sum, counts) => sum + counts.total, 0)}
                          </td>
                        </tr>
                      </>
                    );
                  })()}
                </tbody>
              </table>
            </div>
          </div>
          </>
        ) : (
          <div className="text-center p-5 text-gray-600 bg-gray-50 rounded-md mb-2">
            No errors found for the selected period
          </div>
        )}
      </div>
   </div>
 );
};

export default ErrorTable;