import React, { useState, useEffect, useRef } from 'react';
import RadialChartComponent from './RadialChartComponent';
import DomainSelect from './DomainSelect';
import LandingSelect from './LandingSelect';
import CountrySelect from './CountrySelect';
import { DateRange } from 'react-date-range';
import { format, parseISO } from 'date-fns';
import { enUS } from 'date-fns/locale';
import styled from 'styled-components';
import { Settings2 } from 'lucide-react';
import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates, useSortable, horizontalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExport, faShareNodes, faCheck } from '@fortawesome/free-solid-svg-icons';

const getQueryParams = () => {
  const params = new URLSearchParams(window.location.search);
  
  // Для каждого параметра сначала проверяем его наличие
  const columnsParam = params.get('columns');
  const orderParam = params.get('order');
  const landingsParam = params.get('landings');
  const countryParam = params.get('country');
  const dateRangeParam = params.get('dateRange');

  let dateRange = null;
  if (dateRangeParam) {
    try {
      const parsedRange = JSON.parse(decodeURIComponent(dateRangeParam));
      dateRange = parsedRange.map(range => ({
        ...range,
        startDate: range.startDate ? parseISO(range.startDate) : null,
        endDate: range.endDate ? parseISO(range.endDate) : null,
        key: range.key
      }));
    } catch (e) {
      console.error('Error parsing dateRange:', e);
    }
  }
  
  return {
    visibleColumns: columnsParam ? JSON.parse(decodeURIComponent(columnsParam)) : null,
    columnOrder: orderParam ? JSON.parse(decodeURIComponent(orderParam)) : null,
    domain: params.get('domain') || null,
    landings: landingsParam ? JSON.parse(decodeURIComponent(landingsParam)) : null,
    country: countryParam ? JSON.parse(decodeURIComponent(countryParam)) : null,
    dateRange
  };
};

const updateQueryParams = (params) => {
  const urlParams = new URLSearchParams();
  
  if (params.domain && params.domain !== 'undefined') {
    urlParams.set('domain', params.domain);
  }
  if (params.landings && Array.isArray(params.landings)) {
    urlParams.set('landings', encodeURIComponent(JSON.stringify(params.landings)));
  }
  if (params.country && params.country.value) {
    urlParams.set('country', encodeURIComponent(JSON.stringify(params.country)));
  }
  if (params.dateRange) {
    const dateRangeForUrl = params.dateRange.map(range => ({
      ...range,
      startDate: range.startDate.toISOString(),
      endDate: range.endDate.toISOString(),
      key: range.key
    }));
    urlParams.set('dateRange', encodeURIComponent(JSON.stringify(dateRangeForUrl)));
  }
  if (params.visibleColumns && Array.isArray(params.visibleColumns)) {
    urlParams.set('columns', encodeURIComponent(JSON.stringify(params.visibleColumns)));
  }
  if (params.columnOrder && Array.isArray(params.columnOrder)) {
    urlParams.set('order', encodeURIComponent(JSON.stringify(params.columnOrder)));
  }
 
  // Вместо изменения URL возвращаем сформированную ссылку
  return `${window.location.origin}${window.location.pathname}?${urlParams.toString()}`;
};

const columns = [
  { id: 'date', label: 'Date', default: true },
  { id: 'page_loads', label: 'Page Loads', default: true },
  { id: 'sms_success', label: 'SMS Success', default: true },
  { id: 'registration_success', label: 'Registration Success', default: true },
  { id: 'sms_errors', label: 'SMS Errors', default: true },
  { id: 'registration_errors', label: 'Registration Errors', default: true },
  { id: 'sms_errors_percentage', label: 'SMS Errors %', default: true },
  { id: 'registration_errors_percentage', label: 'Registration Errors %', default: true },
  { id: 'success_rate', label: 'Success Rate %', default: true },
  { id: 'ratio', label: 'Ratio %', default: true }
];

const ColumnFilters = ({ 
  visibleColumns, 
  onColumnChange, 
  columnOrder,
  selectedDomain,
  selectedLandings,
  selectedCountry,
  selectedDateRange 
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleChange = (e, columnId) => {
    const newColumns = e.target.checked 
      ? [...visibleColumns, columnId]
      : visibleColumns.filter(id => id !== columnId);
      
    localStorage.setItem('statisticsVisibleColumns', JSON.stringify(newColumns));
    updateQueryParams({ 
      visibleColumns: newColumns,
      columnOrder,
      domain: selectedDomain,
      landings: selectedLandings,
      country: selectedCountry,
      dateRange: selectedDateRange
    });
    onColumnChange(newColumns);
  };

  return (
    <div className="relative inline-block">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="p-2 text-gray-600 hover:text-gray-900 bg-white rounded-md border border-gray-300 hover:bg-gray-50"
      >
        <Settings2 size={20} />
      </button>

      {isOpen && (
        <div className="absolute right-0 mt-2 w-64 bg-white rounded-lg shadow-lg z-50 border border-gray-200">
          <div className="p-4">
            <div className="mb-2 font-medium text-gray-700">Show Columns</div>
            <div className="space-y-2">
              {columns.map((column) => (
                <label key={column.id} className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    checked={visibleColumns.includes(column.id)}
                    onChange={(e) => handleChange(e, column.id)}
                    className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                  />
                  <span className="text-sm text-gray-600">{column.label}</span>
                </label>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const SortableHeader = ({ id, children }) => {
  const { 
    attributes, 
    listeners, 
    setNodeRef, 
    transform, 
    transition, 
    isDragging 
  } = useSortable({ id });
  
  return (
    <th 
      ref={setNodeRef} 
      className={`
        px-6 py-3 
        border-b border-r border-gray-200 last:border-r-0
        transition-all duration-150
        ${isDragging ? 'bg-transparent' : 'bg-gray-50'}
      `}
      style={{
        transform: CSS.Transform.toString(transform),
        transition,
        cursor: 'grab',
        userSelect: 'none',
        position: 'relative',
        zIndex: isDragging ? 50 : 'auto'
      }}
      {...attributes} 
      {...listeners}
    >
      <div className="flex items-center space-x-2">
        {children}
        <div className="flex flex-col justify-center opacity-30 group-hover:opacity-100 transition-opacity">
         <svg width="16" height="2" className="mb-1">
           <line x1="0" y1="1" x2="16" y2="1" stroke="currentColor" strokeWidth="2"/>
         </svg>
         <svg width="16" height="2" className="mb-1">
           <line x1="0" y1="1" x2="16" y2="1" stroke="currentColor" strokeWidth="2"/>
         </svg>
         <svg width="16" height="2">
           <line x1="0" y1="1" x2="16" y2="1" stroke="currentColor" strokeWidth="2"/>
         </svg>
       </div>
      </div>
    </th>
  );
};

const customLocale = {
  ...enUS,
  options: {
    ...enUS.options,
    weekStartsOn: 1
  }
};

const CustomHeaderTooltip = ({ title, description, children, isLast = false }) => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);
 
  return (
    <div
      ref={ref}
      className="relative inline-block"
      onMouseEnter={() => setIsVisible(true)}
      onMouseLeave={() => setIsVisible(false)}
    >
      {children}
      {isVisible && (
        <div 
          className={`absolute z-[100] top-full ${isLast ? 'right-0' : 'left-0'} px-3 py-2 mt-2 bg-black text-white text-sm rounded-md shadow-lg`}
          style={{ pointerEvents: 'none', maxWidth: '500px', minWidth: '200px' }}
        >
          <div className="font-bold mb-2">{title}</div>
          <div className="text-xs text-gray-300">{description}</div>
        </div>
      )}
    </div>
  );
 };

const DateRangePickerWrapper = styled.div`
  display: inline-block;
  position: relative;
`;

const DateRangeInput = styled.input`
  padding: 8px 12px;
  font-size: 16px;
  height: 37px;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color ease-in-out 0.15s,box-shadow ease-in-out 0.15s;
  cursor: pointer;
`;

const DateRangePickerPopup = styled.div`
  background-color: #fff;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 9999;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
`;

const ButtonWrapper = styled.div`
  margin-left: 10px;
`;

const SubmitButton = styled.button`
  padding: 8px 20px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #0056b3;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const Preloader = styled.div`
  display: inline-block;
  margin-left: 10px;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #ccc;
  border-top-color: #007bff;
  animation: spin 1s infinite linear;

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;

const StatisticsApp = ({ domains: initialDomainsData, landings: initialLandingData }) => {
  const [domains, setDomains] = useState(initialDomainsData);
  const [landings, setLandings] = useState(initialLandingData);
  const [landingOptions, setLandingOptions] = useState([]);
  const [statistics, setStatistics] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDateRangePickerOpen, setIsDateRangePickerOpen] = useState(false);
  const dateRangePickerRef = useRef(null);
  const [copySuccess, setCopySuccess] = useState(false);

  const [selectedDomain, setSelectedDomain] = useState(() => {
    const urlParams = getQueryParams();
    return urlParams.domain || 'all';
  });

  const [selectedLandings, setSelectedLandings] = useState(() => {
    const urlParams = getQueryParams();
    return urlParams.landings || ['all'];
  });

  const [selectedCountry, setSelectedCountry] = useState(() => {
    const urlParams = getQueryParams();
    return urlParams.country || { value: 'All', label: 'All Countries' };
  });

  const [selectedDateRange, setSelectedDateRange] = useState(() => {
    const urlParams = getQueryParams();
    if (urlParams.dateRange) return urlParams.dateRange;
    return [{
      startDate: new Date(new Date().setHours(0, 0, 0, 0)),
      endDate: new Date(new Date().setHours(23, 59, 59, 999)),
      key: 'selection',
    }];
  });

  const [visibleColumns, setVisibleColumns] = useState(() => {
    const urlParams = getQueryParams();
    if (urlParams.visibleColumns) return urlParams.visibleColumns;
    
    const saved = localStorage.getItem('statisticsVisibleColumns');
    return saved ? JSON.parse(saved) : columns.filter(col => col.default).map(col => col.id);
  });
  
  const [columnOrder, setColumnOrder] = useState(() => {
    const urlParams = getQueryParams();
    if (urlParams.columnOrder) return urlParams.columnOrder;
    
    const saved = localStorage.getItem('statisticsColumnOrder');
    return saved ? JSON.parse(saved) : columns.map(col => col.id);
  });

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  useEffect(() => {
    if (selectedDomain === 'all') {
      setLandingOptions([{ id: 'all', name: 'All Landings' }]);
    } else {
      const filteredLandings = landings.filter(landing => landing.domain_id === parseInt(selectedDomain));
      setLandingOptions([{ id: 'all', name: 'All Landings' }, ...filteredLandings]);
    }
  }, [selectedDomain, landings]);

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    try {
      const urlParams = getQueryParams();
      let hasUrlParams = false;

      if (urlParams.visibleColumns) {
        setVisibleColumns(urlParams.visibleColumns);
        hasUrlParams = true;
      }
      
      if (urlParams.visibleColumns) {
        // Применяем настройки из URL, но не сохраняем в localStorage
        setVisibleColumns(urlParams.visibleColumns);
      } else {
        // Если нет параметров в URL, берем из localStorage
        const savedColumns = localStorage.getItem('statisticsVisibleColumns');
        if (savedColumns) {
          setVisibleColumns(JSON.parse(savedColumns));
        }
      }
  
      if (urlParams.columnOrder) {
        // Применяем порядок из URL, но не сохраняем в localStorage
        setColumnOrder(urlParams.columnOrder);
      } else {
        // Если нет параметров в URL, берем из localStorage
        const savedOrder = localStorage.getItem('statisticsColumnOrder');
        if (savedOrder) {
          setColumnOrder(JSON.parse(savedOrder));
        }
      }
      
      // Остальные параметры из URL
      if (urlParams.domain) {
        const domainId = urlParams.domain === 'all' ? 'all' : Number(urlParams.domain);
        setSelectedDomain(domainId);
        if (domainId !== 'all') {
          const filteredLandings = landings.filter(landing => 
            landing.domain_id === domainId
          );
          setLandingOptions([{ id: 'all', name: 'All Landings' }, ...filteredLandings]);
        }
      }
  
      if (urlParams.landings) {
        setSelectedLandings(urlParams.landings);
      }
  
      if (urlParams.country) {
        setSelectedCountry(urlParams.country);
      }
  
      if (urlParams.dateRange && 
          urlParams.dateRange[0] && 
          urlParams.dateRange[0].startDate && 
          urlParams.dateRange[0].endDate) {
        setSelectedDateRange(urlParams.dateRange);
      }

      if (hasUrlParams) {
        window.history.replaceState({}, '', window.location.pathname);
      }
  
    } catch (error) {
      console.error('Error parsing params:', error);
    }
  }, [landings]);

  const handleDragEnd = (event) => {
    const { active, over } = event;
    
    if (active.id !== over.id) {
      const oldIndex = columnOrder.indexOf(active.id);
      const newIndex = columnOrder.indexOf(over.id);
      
      const newOrder = arrayMove(columnOrder, oldIndex, newIndex);
      setColumnOrder(newOrder);
      localStorage.setItem('statisticsColumnOrder', JSON.stringify(newOrder));
      updateQueryParams({ 
        visibleColumns,
        columnOrder: newOrder,
        domain: selectedDomain,
        landings: selectedLandings,
        country: selectedCountry,
        dateRange: selectedDateRange
      });
    }
  };

  const handleColumnVisibilityChange = (newColumns) => {
    setVisibleColumns(newColumns);
    localStorage.setItem('statisticsVisibleColumns', JSON.stringify(newColumns));
    updateQueryParams({ 
      visibleColumns: newColumns,
      columnOrder 
    });
  };

  const handleGetStatistics = async () => {
    setIsLoading(true);
    try {
      const token = document.querySelector('meta[name="csrf-token"]').content;
      
      // Устанавливаем время для startDate и endDate
      const adjustedStartDate = new Date(selectedDateRange[0].startDate);
      adjustedStartDate.setHours(0, 0, 0, 0);
  
      const adjustedEndDate = new Date(selectedDateRange[0].endDate);
      adjustedEndDate.setHours(23, 59, 59, 999);
  
      const response = await fetch('/api/statistics', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': token,
        },
        body: JSON.stringify({
          selectedDomain,
          selectedLandings,
          selectedCountry: selectedCountry.value,
          dateRange: [{
            startDate: adjustedStartDate.toISOString().split('T')[0] + 'T00:00:00.000Z',
            endDate: adjustedEndDate.toISOString().split('T')[0] + 'T23:59:59.999Z'
          }],
        }),
      });
      const data = await response.json();
      setStatistics(data.statistics);
    } catch (error) {
      console.error('Error fetching statistics:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDateRangeFieldClick = () => {
    setIsDateRangePickerOpen(!isDateRangePickerOpen);
  };

  const handleOutsideClick = (event) => {
    if (dateRangePickerRef.current && !dateRangePickerRef.current.contains(event.target)) {
      setIsDateRangePickerOpen(false);
    }
  };

  const handleDateRangeChange = (ranges) => {
    setSelectedDateRange([ranges.selection]);
    updateQueryParams({
      domain: selectedDomain,
      landings: selectedLandings,
      country: selectedCountry,
      dateRange: [ranges.selection],
      visibleColumns,
      columnOrder
    });
  };

  const handleDomainChange = (event) => {
    const selectedDomainId = event.target.value;
    setSelectedDomain(selectedDomainId);
    setSelectedLandings(['all']);
    updateQueryParams({
      domain: selectedDomainId,
      landings: ['all']
    });
  };

  const handleLandingChange = (newLandings) => {
    setSelectedLandings(newLandings);
    updateQueryParams({ landings: newLandings });
  };

  const handleCountryChange = (newCountry) => {
    setSelectedCountry(newCountry);
    updateQueryParams({ country: newCountry });
  };

  const handleCopyLink = () => {
    const link = updateQueryParams({
      domain: selectedDomain,
      landings: selectedLandings,
      country: selectedCountry,
      dateRange: selectedDateRange,
      visibleColumns,
      columnOrder
    });
    
    navigator.clipboard.writeText(link);
    setCopySuccess(true);
    setTimeout(() => {
      setCopySuccess(false);
    }, 2000);
  };



  // Конвертация данных в CSV формат
  const convertToCSV = (data) => {
    const chartData = [
      ['Chart Data'],
      ['Metric', 'Value'],
      ['SMS Success', data.reduce((sum, stat) => sum + stat.sms_success, 0)],
      ['Registration Success', data.reduce((sum, stat) => sum + stat.registration_success, 0)],
      ['SMS Errors', data.reduce((sum, stat) => sum + stat.sms_errors, 0)],
      ['Registration Errors', data.reduce((sum, stat) => sum + stat.registration_errors, 0)],
      [], 
      ['Detailed Data']
    ];
   
    const headers = columnOrder
      .filter(colId => visibleColumns.includes(colId))
      .map(colId => columns.find(col => col.id === colId).label);
   
    const dataToConvert = data.map(stat => {
      const row = {};
      columnOrder
        .filter(colId => visibleColumns.includes(colId))
        .forEach(colId => {
          switch(colId) {
            case 'date':
              row['Date'] = format(new Date(stat.date), 'dd/MM/yyyy');
              break;
            case 'page_loads':
              row['Page Loads'] = stat.page_loads;
              break;
            case 'sms_success':
              row['SMS Success'] = stat.sms_success;
              break;
            case 'registration_success':
              row['Registration Success'] = stat.registration_success;
              break;
            case 'sms_errors':
              row['SMS Errors'] = stat.sms_errors;
              break;
            case 'registration_errors':
              row['Registration Errors'] = stat.registration_errors;
              break;
            case 'sms_errors_percentage':
              row['SMS Errors %'] = stat.sms_success > 0 ? ((stat.sms_errors / stat.sms_success) * 100).toFixed(1) + '%' : '0%';
              break;
            case 'registration_errors_percentage':
              row['Registration Errors %'] = stat.registration_success > 0 ? ((stat.registration_errors / stat.registration_success) * 100).toFixed(1) + '%' : '0%';
              break;
            case 'success_rate':
              row['Success Rate %'] = stat.sms_success > 0 ? ((stat.registration_success / stat.sms_success) * 100).toFixed(1) + '%' : '0%';
              break;
            case 'ratio':
              row['Ratio %'] = stat.page_loads > 0 ? ((stat.registration_success / stat.page_loads) * 100).toFixed(1) + '%' : '0%';
              break;
          }
        });
      return row;
    });
   
    // Add total row
    const totalRow = {};
    columnOrder
      .filter(colId => visibleColumns.includes(colId))
      .forEach(colId => {
        switch(colId) {
          case 'date':
            totalRow['Date'] = 'Total';
            break;
          case 'page_loads':
            totalRow['Page Loads'] = data.reduce((sum, stat) => sum + stat.page_loads, 0);
            break;
          case 'sms_success':
            totalRow['SMS Success'] = data.reduce((sum, stat) => sum + stat.sms_success, 0);
            break;
          case 'registration_success':
            totalRow['Registration Success'] = data.reduce((sum, stat) => sum + stat.registration_success, 0);
            break;
          case 'sms_errors':
            totalRow['SMS Errors'] = data.reduce((sum, stat) => sum + stat.sms_errors, 0);
            break;
          case 'registration_errors':
            totalRow['Registration Errors'] = data.reduce((sum, stat) => sum + stat.registration_errors, 0);
            break;
          case 'sms_errors_percentage':
            totalRow['SMS Errors %'] = (data.reduce((sum, stat) => 
              sum + (stat.sms_success > 0 ? ((stat.sms_errors / stat.sms_success) * 100) : 0), 0) 
              / data.length).toFixed(1) + '%';
            break;
          case 'registration_errors_percentage':
            totalRow['Registration Errors %'] = (data.reduce((sum, stat) => 
              sum + (stat.registration_success > 0 ? ((stat.registration_errors / stat.registration_success) * 100) : 0), 0)
              / data.length).toFixed(1) + '%';
            break;
          case 'success_rate':
            totalRow['Success Rate %'] = (data.reduce((sum, stat) => 
              sum + (stat.sms_success > 0 ? ((stat.registration_success / stat.sms_success) * 100) : 0), 0)
              / data.length).toFixed(1) + '%';
            break;
          case 'ratio':
            totalRow['Ratio %'] = (data.reduce((sum, stat) => 
              sum + (stat.page_loads > 0 ? ((stat.registration_success / stat.page_loads) * 100) : 0), 0)
              / data.length).toFixed(1) + '%';
            break;
        }
      });
    dataToConvert.push(totalRow);
   
    let csv = chartData.map(row => row.join(',')).join('\n');
    csv += '\n' + headers.join(',') + '\n';
    csv += dataToConvert.map(row => headers.map(header => row[header] || '').join(',')).join('\n');
   
    return csv;
  };

// Функция скачивания CSV
const downloadCSV = () => {
  const csv = convertToCSV(statistics.daily_stats);
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);
  
  link.setAttribute('href', url);
  link.setAttribute('download', `statistics_${format(selectedDateRange[0].startDate, 'dd-MM-yyyy')}_${format(selectedDateRange[0].endDate, 'dd-MM-yyyy')}.csv`);
  link.style.visibility = 'hidden';
  
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};

  return (
    <div className="wrapper">
      <div className="block sticky-top">
        <DomainSelect
          domains={domains}
          selectedDomain={selectedDomain}
          onChange={(event) => {
            const domainId = event.target.value === 'all' ? 'all' : Number(event.target.value);
            setSelectedDomain(domainId);
            setSelectedLandings(['all']);
            updateQueryParams({
              domain: domainId,
              landings: ['all'],
              visibleColumns,
              columnOrder,
              country: selectedCountry,
              dateRange: selectedDateRange
            });
          }}
          setDomains={setDomains}
        />

        <LandingSelect
          landings={landingOptions}
          selectedLandings={selectedLandings}
          onChange={(newLandings) => {
            setSelectedLandings(newLandings);
            updateQueryParams({
              domain: selectedDomain,
              landings: newLandings,
              visibleColumns,
              columnOrder
            });
          }}
          setLandings={setLandings}
        />

        <CountrySelect
          selectedCountry={selectedCountry}
          onChange={(newCountry) => {
            setSelectedCountry(newCountry);
            updateQueryParams({
              domain: selectedDomain,
              landings: selectedLandings,
              country: newCountry,
              visibleColumns,
              columnOrder
            });
          }}
        />

        <DateRangePickerWrapper ref={dateRangePickerRef}>
          <DateRangeInput
            type="text"
            value={`${format(selectedDateRange[0].startDate, 'dd/MM/yyyy')} - ${format(
              selectedDateRange[0].endDate,
              'dd/MM/yyyy'
            )}`}
            readOnly
            onClick={handleDateRangeFieldClick}
            className="datepicker-input"
          />
          {isDateRangePickerOpen && (
            <DateRangePickerPopup>
              <DateRange
                ranges={selectedDateRange}
                onChange={handleDateRangeChange}
                locale={customLocale}
                months={1}
                direction="horizontal"
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
              />
            </DateRangePickerPopup>
          )}
        </DateRangePickerWrapper>

        <ButtonWrapper className="flex items-center">
          <SubmitButton 
            onClick={handleGetStatistics}
            disabled={isLoading}
          >
            {isLoading ? <Preloader /> : 'Get Statistics'}
          </SubmitButton>
          <div className="ml-2">
            <ColumnFilters 
              visibleColumns={visibleColumns}
              onColumnChange={setVisibleColumns}
              columnOrder={columnOrder}
              selectedDomain={selectedDomain}
              selectedLandings={selectedLandings}
              selectedCountry={selectedCountry}
              selectedDateRange={selectedDateRange}
            />
          </div>
        </ButtonWrapper>
      </div>

      {statistics && (
        <div className="block">
          <div className="mx-auto w-full flex flex-col gap-4">
            <div className="grid grid-cols-4 gap-4">

              {/* Информационный блок */}
              <div className="h-fit bg-white shadow-md rounded-lg p-4">
                <div className="grid grid-cols-1 gap-2">
                  <div className="text-sm">
                    <span className="font-semibold text-gray-600">Domain: </span>
                    <span className="text-blue-600">
                      {selectedDomain === 'all' ? 'All Domains' : domains.find(d => d.id === parseInt(selectedDomain))?.name || 'All Domains'}
                    </span>
                  </div>
                  <div className="text-sm">
                    <span className="font-semibold text-gray-600">Landing: </span>
                    <span className="text-blue-600">
                      {selectedLandings.includes('all') 
                        ? 'All Landings' 
                        : selectedLandings.map(id => 
                            landings.find(l => l.id.toString() === id)?.name
                          ).filter(Boolean).join(', ') || 'All Landings'
                      }
                    </span>
                  </div>
                  <div className="text-sm">
                    <span className="font-semibold text-gray-600">Country: </span>
                    <span className="text-blue-600">
                      {selectedCountry.label}
                    </span>
                  </div>
                  <div className="text-sm">
                    <span className="font-semibold text-gray-600">Period: </span>
                    <span className="text-blue-600">
                      {format(selectedDateRange[0].startDate, 'dd/MM/yyyy')} - {format(selectedDateRange[0].endDate, 'dd/MM/yyyy')}
                    </span>
                  </div>
                  <div className="pt-2 mt-2 border-t border-gray-200 space-y-2">
                    <button
                      onClick={downloadCSV}
                      className="w-full px-4 py-2 text-sm font-medium text-white bg-green-600 rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 flex items-center justify-center gap-2 transition-colors duration-200"
                    >
                      <FontAwesomeIcon icon={faFileExport} className="h-4 w-4" />
                      Export to CSV
                    </button>
                    <button
                      onClick={handleCopyLink}
                      className="w-full px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 flex items-center justify-center gap-2 transition-all duration-200"
                      disabled={copySuccess}
                    >
                      <FontAwesomeIcon 
                        icon={copySuccess ? faCheck : faShareNodes} 
                        className={`h-4 w-4 transition-transform duration-200 ${copySuccess ? 'scale-110' : ''}`}
                      />
                      {copySuccess ? 'Link Copied!' : 'Share Link'}
                    </button>
                  </div>
                </div>
                {copySuccess && (
                  <div 
                    className={`
                      fixed bottom-4 right-4 
                      bg-gray-800 text-white 
                      px-4 py-2 
                      rounded-md shadow-lg 
                      z-50 
                      flex items-center gap-2 
                      transform transition-all duration-300 ease-out 
                      translate-y-0 opacity-100
                    `}
                  >
                    <FontAwesomeIcon icon={faCheck} className="h-4 w-4 text-green-400" />
                    Link copied to clipboard!
                  </div>
                )}
              </div>
              
              {/* Радиальный график */}
              <div className="col-span-3">
                <RadialChartComponent statistics={statistics} />
              </div>
            </div>

            

            {/* Таблица */}
            <div className="overflow-x-auto border border-gray-200 rounded-lg shadow">
            <DndContext 
              sensors={sensors} 
              collisionDetection={closestCenter} 
              onDragEnd={handleDragEnd}
            >
              <table className="min-w-full divide-y divide-gray-200">
                <thead>
                    <tr>
                      <SortableContext 
                        items={columnOrder} 
                        strategy={horizontalListSortingStrategy}
                      >
                        {columnOrder
                          .filter(colId => visibleColumns.includes(colId))
                          .map(colId => {
                            const column = columns.find(c => c.id === colId);
                            return (
                              <SortableHeader key={colId} id={colId}>
                                <CustomHeaderTooltip 
                                  title={column.label}
                                  description={
                                    colId === 'date' ? "Date when statistics were collected" :
                                    colId === 'page_loads' ? "Number of unique visits to the landing page" :
                                    colId === 'sms_success' ? "Number of successfully sent SMS validation codes" :
                                    colId === 'registration_success' ? "Number of users who successfully completed registration" :
                                    colId === 'sms_errors' ? "Count of failed SMS validation attempts" :
                                    colId === 'registration_errors' ? "Count of failed registration attempts after SMS validation" :
                                    colId === 'sms_errors_percentage' ? "Percentage of SMS errors from total SMS attempts" :
                                    colId === 'registration_errors_percentage' ? "Percentage of registration errors from total registration attempts" :
                                    colId === 'success_rate' ? "Percentage of successful registrations from successful SMS validations" :
                                    "Percentage of successful registrations from total page loads"
                                  }
                                  isLast={colId === columnOrder[columnOrder.length - 1]}
                                >
                                  <span className="text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    {column.label}
                                  </span>
                                </CustomHeaderTooltip>
                              </SortableHeader>
                            );
                          })}
                      </SortableContext>
                    </tr>
                </thead>

                <tbody className="divide-y divide-gray-200">
                  {statistics.daily_stats.map((stat, index) => (
                    <tr key={index} className="hover:bg-gray-50 transition-colors">
                      {columnOrder
                        .filter(colId => visibleColumns.includes(colId))
                        .map(colId => {
                          const cellContent = (() => {
                            switch(colId) {
                              case 'date':
                                return format(new Date(stat.date), 'dd/MM/yyyy');
                              case 'page_loads':
                                return stat.page_loads;
                              case 'sms_success':
                                return stat.sms_success;
                              case 'registration_success':
                                return stat.registration_success;
                              case 'sms_errors':
                                return stat.sms_errors;
                              case 'registration_errors':
                                return stat.registration_errors;
                              case 'sms_errors_percentage':
                                return `${stat.sms_success > 0 ? ((stat.sms_errors / stat.sms_success) * 100).toFixed(1) : '0'}%`;
                              case 'registration_errors_percentage':
                                return `${stat.registration_success > 0 ? ((stat.registration_errors / stat.registration_success) * 100).toFixed(1) : '0'}%`;
                              case 'success_rate':
                                return `${stat.sms_success > 0 ? ((stat.registration_success / stat.sms_success) * 100).toFixed(1) : '0'}%`;
                              case 'ratio':
                                return `${stat.page_loads > 0 ? ((stat.registration_success / stat.page_loads) * 100).toFixed(1) : '0'}%`;
                              default:
                                return '';
                            }
                          })();

                          const isPercentage = ['sms_errors_percentage', 'registration_errors_percentage', 'success_rate', 'ratio'].includes(colId);

                          return (
                            <td 
                              key={colId} 
                              className={`px-6 py-4 whitespace-nowrap text-sm border-r border-gray-200 last:border-r-0 
                                ${isPercentage ? 'text-blue-600 font-semibold' : 'text-gray-600'}`}
                            >
                              {cellContent}
                            </td>
                          );
                      })}
                    </tr>
                  ))}
                  
                  {/* Total row */}
                  <tr className="bg-gray-50 font-bold border-t-2 border-gray-200">
                    {columnOrder
                      .filter(colId => visibleColumns.includes(colId))
                      .map(colId => {
                        const totalContent = (() => {
                          switch(colId) {
                            case 'date':
                              return 'Total';
                            case 'page_loads':
                              return statistics.daily_stats.reduce((sum, stat) => sum + stat.page_loads, 0);
                            case 'sms_success':
                              return statistics.daily_stats.reduce((sum, stat) => sum + stat.sms_success, 0);
                            case 'registration_success':
                              return statistics.daily_stats.reduce((sum, stat) => sum + stat.registration_success, 0);
                            case 'sms_errors':
                              return statistics.daily_stats.reduce((sum, stat) => sum + stat.sms_errors, 0);
                            case 'registration_errors':
                              return statistics.daily_stats.reduce((sum, stat) => sum + stat.registration_errors, 0);
                            case 'sms_errors_percentage':
                              return `${(statistics.daily_stats.reduce((sum, stat) => 
                                sum + (stat.sms_success > 0 ? ((stat.sms_errors / stat.sms_success) * 100) : 0), 0) 
                                / statistics.daily_stats.length).toFixed(1)}%`;
                            case 'registration_errors_percentage':
                              return `${(statistics.daily_stats.reduce((sum, stat) => 
                                sum + (stat.registration_success > 0 ? ((stat.registration_errors / stat.registration_success) * 100) : 0), 0)
                                / statistics.daily_stats.length).toFixed(1)}%`;
                            case 'success_rate':
                              return `${(statistics.daily_stats.reduce((sum, stat) => 
                                sum + (stat.sms_success > 0 ? ((stat.registration_success / stat.sms_success) * 100) : 0), 0)
                                / statistics.daily_stats.length).toFixed(1)}%`;
                            case 'ratio':
                              return `${(statistics.daily_stats.reduce((sum, stat) => 
                                sum + (stat.page_loads > 0 ? ((stat.registration_success / stat.page_loads) * 100) : 0), 0)
                                / statistics.daily_stats.length).toFixed(1)}%`;
                            default:
                              return '';
                          }
                        })();

                        const isPercentage = ['sms_errors_percentage', 'registration_errors_percentage', 'success_rate', 'ratio'].includes(colId);

                        return (
                          <td 
                            key={colId} 
                            className={`px-6 py-4 whitespace-nowrap text-sm border-r border-gray-200 last:border-r-0
                              ${isPercentage ? 'text-blue-600' : 'text-gray-900'}`}
                          >
                            {totalContent}
                          </td>
                        );
                      })}
                  </tr>
                </tbody>
            
              </table>
            </DndContext>
          </div>

          </div>
        </div>
      )}
    </div>
  );
};

export default StatisticsApp;